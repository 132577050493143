@import "../../assets/scss/_settings.colors.scss";
@import "../../assets/scss/_tools.fonts.scss";

.iwk-logo {
  align-items: center;
  display: flex;
  flex-direction: column;

  &__icon {
    margin-bottom: 0.5rem;
    width: 22rem;
  }

  &__text {
    @include font-16;
    @include font-bold;
    color: $brand-blue-light;
  }
}