.icon-with-label {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 0.125rem 0;
  font-size: 0.625rem; }
  @media (min-width: 1056px) {
    .icon-with-label {
      font-size: 0.875rem; } }

.icon-with-label__icon {
  height: 1.5rem;
  width: 1.5rem;
  margin-bottom: 0.25rem;
  fill: #fff; }
  @media (min-width: 1056px) {
    .icon-with-label__icon {
      height: 1.25rem;
      width: 1.25rem; } }

.icon-with-label__label {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: normal;
  line-height: 1;
  font-weight: 400; }

.icon-without-label__icon .svg-outline {
  transition: fill 300ms ease;
  fill: white; }

.icon-without-label__icon .svg-fill {
  transition: fill 300ms ease;
  fill: transparent; }

.icon-without-label__icon--invert-color .svg-outline {
  fill: transparent; }

.icon-without-label__icon--invert-color .svg-fill {
  fill: white; }
