.discharge-path__container {
  background-color: #e7f7f6;
  background-size: auto 5rem;
  padding-bottom: 2rem;

  .progress-icon {
    width: 100%;
    height: 100%;
  }

  .progress-icon__image {
    width: 90%;
    height: 90%;
    top: 5px;
    left: 5px;
  }

  .discharge-home-icon-background {
    fill: #fff;
  }

  .discharge-home-icon {
    fill: #10a0a2;
    transform: translate3d(1.6rem, 1.5rem, 0);
  }

  .discharge-home-icon-background-complete {
    fill: #10a0a2;
  }

  .discharge-home-icon-complete {
    fill: #fff;
    transform: translate3d(1.6rem, 1.5rem, 0);
  }
}

.discharge-path__svg {
  width: 100%;

  @media (min-width: 1024px) {
    margin-bottom: 0;
  }

  .nuclei-text {
    transform: translate(3.5625rem, -4.5rem);
    text-align: left;
    fill: #5365aa;
    font-size: 16px;

    @media (min-width: 1024px) {
      transform: translate(0, 0);
    }
  }
}

.discharge-path-tooltip p {
  margin-bottom: 0.5rem;
}
