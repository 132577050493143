@import "../../assets/scss/_settings.colors.scss";
@import "../../assets/scss/_tools.fonts.scss";

.iwk-stepper {
  align-items: flex-start;
  display: flex;
  flex-direction: row;

  &__label {
    @include font-16;
    border: 1px solid $form-control-border;
    border-radius: 2px;
    padding: 1.2rem;
    text-align: center;
    width: 18.4rem;
  }

  &__button {
    &.btn.btn-primary {
      height: 4.5rem;
      padding: 0;
      width: 4.5rem;
      @include font-25;
    }

    &.-decrease {
      margin-right: 0.8rem;
    }

    &.-increase {
      margin-left: 0.8rem;
    }
  }
}