@import "../../assets/scss/_settings.colors.scss";

.iwk-sidebar {
  background-color: $neutral-white;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 8.1rem;

  &__link {
    border: none;
    display: flex;
    height: 6.2rem;
    justify-content: center;
    outline: none;

    &:hover, &.-active {
      background-color: $brand-blue;

      .fill {
        fill: $neutral-white !important;
      }
    }

    &-icon {
      width: 2.5rem;
    }
  }
}