@import "../../../assets/scss/_elements.tabs.scss";
@import "../../../assets/scss/_settings.colors.scss";
@import "../../../assets/scss/_tools.fonts.scss";
@import "../../../assets/scss/_tools.tab-layout.scss";

.iwk-family-dashboard {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__header {
    display: flex;
    flex-direction: column;

    &-actions {
      display: flex;
      justify-content: space-between;
    }

    &-link {
      @include font-14;
      align-items: center;
      display: flex;
      margin: 2.4rem 0 2.5rem 3.6rem;

      &:hover {
        @include font-bold;
      }

      &-icon {
        margin-right: 0.6rem;
        width: 1.4rem;
      }
    }

    &-info {
      align-items: center;
      display: flex;
      margin: 1.2rem 3.5rem 1.3rem 0;
    }

    &-id {
      @include font-16;
      @include font-bold;
      margin-left: 2.1rem;
    }

    &-tabs {
      @include tab-layout;
    }

    &-tab {
      @include tab;
    }
  }
}