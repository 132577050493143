@import "../../../../../assets/scss/_elements.table.scss";

.iwk-family-row {
  @include table-row;
  
  &__cell {
    @include table-cell;
    
    &-invite {
      width: 12rem;
    }

    &-button {
      @include table-button;
    }

    &-icon {
      height: 2rem;
    }
  }
}