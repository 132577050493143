@import "../../../../assets/scss/_elements.buttons.scss";
@import "../../../../assets/scss/_elements.table.scss";
@import "../../../../assets/scss/_tools.dashboard-layout.scss";

.iwk-babies {
  @include dashboard-layout;

  &__actions {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    &-count {
      @include font-16;
      margin-right: 3.335rem;
    }
  }

  &__table {
    @include table;

    &-header {
      @include table-header-row;

      &-cell {
        @include table-cell;
      }
    }
  }
}