@import "../../assets/scss/_tools.fonts.scss";
@import "../../assets/scss/_tools.forms.scss";
@import "../../assets/scss/_tools.login-layout.scss";

.iwk-reset-password {
  @include login-layout;

  &__info {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 3.6rem;

    &-header {
      @include font-32;
    }

    &-message {
      @include font-16;
      line-height: 2.4rem;
      margin: 1.5rem 0;
      text-align: center;
    }
  }

  &__form {
    width: 32rem;

    &-error {
      @include form-error;
    }

    &-actions {
      margin-top: 4.9rem;

      &-button {
        margin: 0 0.75rem;
      }
    }
  }
}