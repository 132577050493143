@import "./assets/scss/_elements.modal";
@import "./assets/scss/_settings.colors";
@import "./assets/scss/_tools.fonts";
@import "./assets/scss/_tools.links";

body, html, #root {
  @include font-regular;
  font-size: 62.5%;
  height: 100%;
}

a {
  @include link;
}