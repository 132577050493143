.progress-icon {
  border-radius: 50%;
  width: 140px;
  height: 140px;
  position: relative;
  background: white; }

.progress-icon__image {
  border-radius: 50%;
  left: 11px;
  top: 11px;
  position: absolute; }

.progress-icon__checkmark {
  width: 2.75rem;
  height: 2.75rem;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center; }
  .progress-icon__checkmark .edu-icon {
    height: 1.5rem;
    width: 1.5rem;
    display: block;
    margin: auto;
    fill: #fff; }

.progress-icon--red .CircularProgressbar-trail {
  stroke: #f7f4fd; }

.progress-icon--red .CircularProgressbar-path {
  stroke: #e76e6f; }

.progress-icon--red .progress-icon__checkmark {
  background-color: #e76e6f; }

.progress-icon--blue .CircularProgressbar-trail {
  stroke: #f7f4fd; }

.progress-icon--blue .CircularProgressbar-path {
  stroke: #6c82d1; }

.progress-icon--blue .progress-icon__checkmark {
  background-color: #6c82d1; }

.progress-icon--green .CircularProgressbar-trail {
  stroke: #f7f4fd; }

.progress-icon--green .CircularProgressbar-path {
  stroke: #10a0a2; }

.progress-icon--green .progress-icon__checkmark {
  background-color: #10a0a2; }

.progress-icon--empty .CircularProgressbar-path {
  display: none; }
