@import "../../../../assets/scss/_elements.buttons.scss";
@import "../../../../assets/scss/_elements.table.scss";
@import "../../../../assets/scss/_tools.dashboard-layout.scss";
@import "../../../../assets/scss/_tools.search.scss";
@import "../../../../shared";

.iwk-table-header-buttons {
  display: flex;
}

.iwk-families {
  @include dashboard-layout;

  &__search {
    @include search;
  }

  &__actions {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-shrink: 0; // for Safari 
    justify-content: space-between;
    width: 100%;

    &-count {
      @include font-16;
      margin-right: 3.335rem;
    }
  }

  &__table {
    @include table;

    &-header {
      @include table-header-row;
      
      &-cell {
        flex-basis: 25%;
        flex-grow: 0;

        &.-small {
          flex-basis: 10%;
        }

        &.-large {
          flex-basis: 40%;
          margin-right: 2rem;
        }

        @media(min-width: $supermax-width) {
          &.-extend {
            flex-basis: 10%;
          }
        }
      }
    }
  }
}