@mixin font-regular {
  font-family: "proxima-nova-regular" !important;
}

@mixin font-bold {
  font-family: "proxima-nova-semibold" !important;
}

@mixin font-light {
  font-family: "proxima-nova-light" !important;
}

@mixin font-form-spacing {
  letter-spacing: 0.082rem;
}

@mixin font-10() {
  font-size: 1rem;
  line-height: 1.5em;
}

@mixin font-12() {
  font-size: 1.2rem;
  line-height: 1.5em;
}

@mixin font-14() {
  font-size: 1.4rem;
  line-height: 1.5em;
}

@mixin font-16() {
  font-size: 1.6rem;
  line-height: 2rem;
}

@mixin font-18() {
  font-size: 1.8rem;
  line-height: 2.2rem;
}

@mixin font-20() {
  font-size: 2rem;
  line-height: 2.4rem;
}

@mixin font-21() {
  font-size: 2.1rem;
  line-height: 1.5em;
}

@mixin font-24() {
  font-size: 2.5rem;
  line-height: 2.9rem;
}

@mixin font-25() {
  font-size: 2.5rem;
  line-height: 1.5em;
}

@mixin font-28() {
  font-size: 2.8rem;
  line-height: 1.5em;
}

@mixin font-32() {
  font-size: 3.2rem;
  line-height: 1.5em;
}

@mixin font-34() {
  font-size: 3.4rem;
}

@mixin font-38() {
  font-size: 3.8rem;
  line-height: 1.5em;
}

@mixin font-50() {
  font-size: 5rem;
  line-height: 1.2em;
}

@mixin font-67() {
  font-size: 6.7rem;
  line-height: 0.85em;
}
