@import "../../../../assets/scss/_elements.buttons.scss";
@import "../../../../assets/scss/_elements.table.scss";
@import "../../../../assets/scss/_tools.dashboard-layout.scss";
@import "../../../../assets/scss/_tools.search.scss";
@import "../../../../_shared.scss";

.iwk-staff {
  @include dashboard-layout;

  &__search {
    @include search;
  }

  &__actions {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-shrink: 0; // for Safari
    justify-content: space-between;
    width: 100%;

    &-delete {
      margin-right: 0.8rem;

      &.btn, .btn-secondary {
        padding: 0;
      }

      &-icon {
        height: 2.094rem;
        margin: 1rem;
        width: 2rem;
      }
    }

    &-deactivate {
      margin: 0 0.8rem;

      &.btn, .btn-secondary {
        @include font-light;
      }
    }

    &-value, &-separator {
      @include font-16;
      margin-right: .5rem;

      @media (min-width: $supermax-breakpoint) {
        margin-right: 3.335rem;
      }
    }
  }

  &__table {
    @include table;

    &-header {
      @include table-header-row;

      &-cell {
        @include table-cell;
      }
    }
  }
}