@import "../../assets/scss/_tools.fonts.scss";
@import "../../assets/scss/_tools.forms.scss";

.iwk-error {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  flex-direction: column;
  padding: 0 1rem;

  &__info {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 3.6rem;

    &-header {
      @include font-32;
      text-align: center;
    }

    &-message {
      @include font-16;
      line-height: 2.4rem;
      margin: 1.5rem 0;
      text-align: center;
    }
  }
}
