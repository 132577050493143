@import "../../assets/scss/_tools.forms.scss";
@import "../../assets/scss/_settings.colors.scss";

.iwk-dropdown {
  &__error {
    @include form-error;
    margin-top: 0.2rem;
    height: 100%;
  }

  .select__menu {
    z-index: 1;

    &-list {
      overflow-x: hidden;
    }
  }

  .select__control {
    width: 100%;
  }

  // dropdown icon colors
  .css-tj5bde-Svg {
    color: $brand-blue;
  }
}