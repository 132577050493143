@import "../../assets/scss/_tools.fonts.scss";

.iwk-table-header-buttons {
  &__delete {
    margin-right: 0.8rem;

    &.btn, .btn-secondary {
      padding: 0;
    }

    &-icon {
      height: 2.094rem;
      margin: 1rem;
      width: 2rem;
    }
  }

  &__activate {
    margin: 0 0.8rem;

    &.btn, .btn-secondary {
      @include font-light;
    }
  }
}