@import "../../assets/scss/_tools.fonts.scss";
@import "../../assets/scss/_settings.colors.scss";

.iwk-header {
  background-color: $neutral-white;
  box-shadow: 0 2px 5px 0 rgba(108,130,209,0.2);
  display: flex;
  height: 7.1rem;
  justify-content: space-between;

  &__logo {
    padding: 1.5rem 0 1.6rem 2.8rem;

    &-icon {
      width: 4rem;
    }
  }

  &__actions {
    display: flex;
    padding: 2.8rem 1.4rem 2.6rem 0;

    &-action {
      align-items: center;
      display: flex;
    }

    &-change {
      position: relative;
      left: 25rem;
      top: 2.2rem;

      border: none;
      color: $brand-blue;
      outline: none;
      background:none;

      &:focus {
        outline: none;
      }
    }

    &-icon {
      margin-right: 0.6rem;
      width: 2rem;
      fill: $brand-blue;
    }
    
    &-button {
      background: $neutral-white;
      border: none;
      color: $brand-blue;
      font-size: 1.4rem;
      margin-right: 2.5rem;
      outline: none;

      // on hover font is bold, this stops the icon from jumping
      &.-frontend {
        padding: 0 0.3rem 0 0;
      }

      // on hover font is bold, this stops the icon from jumping
      &.-account {
        padding: 0 0.2rem 0 0;
      }

      &.-logout {
        padding: 0 0.1rem 0 0;
      }

      &:focus {
        outline: none;
      }

      &:hover {
        @include font-bold;
        padding: 0;
      }
    }
  }
}