@import "../../../assets/scss/_elements.buttons.scss";
@import "../../../assets/scss/_elements.table.scss";
@import "../../../assets/scss/_tools.dashboard-layout.scss";
@import "../../../assets/scss/_tools.search.scss";
@import "../../../assets/scss/_tools.fonts.scss";

.iwk-general-settings {
  @include dashboard-layout;

  &__actions {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 4.3rem;
    justify-content: flex-end;
    width: 100%;

    &-cancel, &-save {
      width: 9.2rem;
    }

    &-cancel {
      margin-right: 1.3rem;
    }
  }

  &__settings {
    width: 100%;
    background-color: $neutral-white;
    padding: 4.4rem;
    margin-top: 1.7rem;

    &-title {
      @include font-25;
    }
  }

  &__timeout {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2rem;

    &-message {
      @include font-16;
    }
  }
}