@font-face {
  font-family: "proxima-nova-regular";
  src: url('../fonts/proximanova-regular-webfont.woff2') format('woff2'),
    url('../fonts/proximanova-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "proxima-nova-regularit";
  src: url('../fonts/proximanova-regularit-webfont.woff2') format('woff2'),
    url('../fonts/proximanova-regularit-webfont.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "proxima-nova-semibold";
  src: url('../fonts/proximanova-semibold-webfont.woff2') format('woff2'),
    url('../fonts/proximanova-semibold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "proxima-nova-semiboldit";
  src: url('../fonts/proximanova-semiboldit-webfont.woff2') format('woff2'),
    url('../fonts/proximanova-semiboldit-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "proxima-nova-light";
  src: url('../fonts/proximanova-light-webfont.woff2') format('woff2'),
    url('../fonts/proximanova-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "proxima-nova-lightit";
  src: url('../fonts/proximanova-lightit-webfont.woff2') format('woff2'),
    url('../fonts/proximanova-lightit-webfont.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}