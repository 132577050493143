@import "../../../assets/scss/_elements.tabs.scss";
@import "../../../assets/scss/_tools.tab-layout.scss";

.iwk-staff-families-dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__header {
    @include tab-layout;

    &-tab {
      @include tab;
    }
  }
}