@import "../../../../../assets/scss/_elements.table.scss";

.iwk-babies-row {
  @include table-row;

  &__cell {
    @include table-cell;

    &-button {
      @include table-button;
    }

    &-icon {
      height: 2rem;
    }
  }
}