@import "./_settings.colors";

form {
  width: 100%;
}

.form-label, .form-control {
  @include font-14;
  @include font-form-spacing;
}

.form-control {
  border: 1px solid $form-control-border;
  color: $form-control-text;
  padding: 2rem 1.6rem;

  &:focus {
    border: 1px solid $accent-turquoise;
    box-shadow: none;
    outline: none;
  }
}