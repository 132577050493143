@import "../scss/settings.colors.scss";
@import "../scss/elements.buttons.scss";

.modal-content {
  @include font-16;
  @include font-regular;
  align-items: center;
  box-shadow: 0 46px 100px 0 rgba(0, 0, 0, 0.15);
  color: $form-control-text;
  display: flex;
  border: none;
  flex-direction: column;
  max-height: 60rem;
}

.modal-title {
  @include font-21;
  @include font-regular;
  color: $form-control-text;
  line-height: 3.5rem;
}

.modal-header {
  background-color: $neutral-white;
  padding: 2.8rem 2.4rem 2.4rem 2.4rem;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
}

.modal-footer {
  background-color: $neutral-white;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2.3rem 2.4rem;
  position: sticky;
  width: 100%;
}

.modal-header,
.modal-footer,
.modal-body {
  border: none;

  .btn {
    width: 15rem;

    &.btn-secondary {
      margin-right: 2rem;
    }
  }
}

.modal-body {
  left: 0;
  height: 100%;
  padding: 0 2.4rem;
}

.modal-body,
.modal-footer {
  align-self: center;
  text-align: center;
}

.modal-backdrop {
  &.show {
    opacity: 0.35;
  }
  background-color: $brand-blue;
}

.close {
  @include font-34;
  @include font-light;
  color: $brand-blue;
  line-height: 1;
  opacity: 1;
  text-shadow: none;

  &:hover {
    color: $brand-blue;
  }

  &:not(:disabled):active,
  &:focus {
    outline: none;
  }
}

// over-riding max-width for the medium sized modal
.modal-md {
  margin: 1rem auto;
  max-width: 36.8rem;
}
