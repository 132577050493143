@import "./_settings.colors";
@import "./_tools.fonts";

@mixin table {
  @include font-14;
  @include font-regular;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 2.5rem;
  width: 100%;
}

@mixin table-row {
  align-items: center;
  background-color: $neutral-white;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0; // for Safari
  justify-content: space-between;
  margin-bottom: 0.1rem;
  padding: 1.0rem 0.7rem;
  width: 100%;
}

@mixin table-header-row {
  @include table-row;
  @include font-bold;
  background-color: $neutral-lighter;
  position: sticky;
  top: 0;
  z-index: 1;
}

@mixin table-cell {
  display: flex;
  flex: 1;
  width: 100%;
  padding: 1rem;

  &.-small {
    flex: 0.5;
  }
}

@mixin table-button {
  background: $neutral-white;
  border: none;
  outline: none;
  padding: 1rem;
  position: relative;

  &:focus {
    outline: none;
  }

  &:hover {
    border-radius: 2px;
    box-shadow: 0 7px 10px 0 rgba(87,104,168,.5);
  }

  &.-edit {
    margin-left: 1.4rem;
  }
}