@import "_tools.fonts";

@mixin link {
  @include font-14;
  color: $brand-blue;

  &:hover {
    color: $brand-blue;
    text-decoration: none;
    cursor: pointer;
  }
}