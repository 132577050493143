@import "../../assets/scss/_settings.colors.scss";

.iwk-search {
  height: 4.8rem;
  margin: 1rem 3.5rem 0.7rem 0;
  position: relative;
  width: 32rem;

  &__input {
    color: $form-control-text;
    font-size: 1.4rem;
    height: 4.8rem;
    padding: 1.6rem 4.5rem 1.5rem 1.6rem;
  }

  &__icon {
    position: absolute;
    height: 2.4rem;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 2.4rem;
  }
}