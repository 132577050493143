@import "../../assets/scss/_settings.colors.scss";

.iwk-loading {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;

  &__loader {
    animation: spin 1s linear infinite;
    border: 1rem solid $accent-light;
    border-radius: 50%;
    border-top: 1rem solid $brand-blue;
    height: 12rem;
    width: 12rem;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}