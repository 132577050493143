@import "_settings.colors";

@mixin dashboard-layout {
  background-color: $neutral-lighter;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2.8rem 3.7rem;
  position: relative;
  width: 100%;
}