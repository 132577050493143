@import "./_settings.colors";
@import "./_tools.fonts";

$button-border-radius: 2px;
$button-hover-shadow: 0 7px 10px 0 rgba(87, 104, 168, 0.5);
$button-padding: 1rem 2rem;

.btn {
  @include font-14;
  @include font-bold;
  border-radius: $button-border-radius;
  padding: $button-padding;

  &:not(:disabled):hover {
    background-color: $brand-blue-dark;
    border-color: $brand-blue-dark;
    box-shadow: $button-hover-shadow;
  }

  &.btn-primary {
    background-color: $brand-blue;
    border-color: $brand-blue;
    color: $neutral-white;

    &:disabled {
      background-color: $neutral-light;
      border-color: $neutral-light;
    }

    &:focus, &:focus-within {
      border: 1px solid $accent-turquoise;
      box-shadow: none;
      outline: none;
    }

    &:not(:disabled):hover {
      background-color: $brand-blue-dark;
      border-color: $brand-blue-dark;
      box-shadow: $button-hover-shadow;
    }

    // :active MUST come after hover for the style to be applied properly
    &:not(:disabled):active {
      background-color: $brand-blue-light;
      border: 1px solid $brand-blue-light;
    }
  }

  &.btn-secondary {
    background-color: $neutral-white;
    border-color: $brand-blue;
    color: $brand-blue;

    &:disabled {
      border-color: $neutral-light;
      color: $neutral-light;
    }

    &:focus, &:focus-within {
      border: 1px solid $accent-turquoise;
      box-shadow: none;
      outline: none;
    }

    &:not(:disabled):hover {
      background-color: $accent-light;
      border-color: $brand-blue;
      box-shadow: $button-hover-shadow;
    }

    // :active MUST come after hover for the style to be applied properly
    &:not(:disabled):active {
      box-shadow: none;
      background-color: $brand-blue-light;
      border-color: $brand-blue-light;
      color: $neutral-white;
    }
  }

  &.modal-button {
    @include font-38;
    @include font-light;
    border: 1px solid $brand-blue;
    border-radius: 50%;
    height: 4.65rem;
    line-height: 1rem;
    padding: 1rem;
    text-align: center;
    width: 4.65rem;
  }
}