@import "../../../../assets/scss/_tools.dashboard-layout.scss";
@import "../../../../assets/scss/_tools.fonts.scss";
@import "../../../../assets/scss/_settings.colors.scss";

.iwk-discharge-report {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  @include dashboard-layout;

  &-download {
    &__row {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      margin-bottom: 1.6rem;
    }

    &__button {
      width: 11rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &-pdf {
    background-color: $neutral-white;
    &__header {
      background-color: #e7f7f6;
      padding: 3.7rem 0 0 3.6rem;

      &-title {
        opacity: 0.9;
        color: #212529;
        @include font-24;

        letter-spacing: -0.45px;
      }

      &-family-id {
        color: #212529;
        @include font-14;

        letter-spacing: 0.82px;
        margin: 0;
      }
    }

    &__footer {
      background-color: $neutral-white;
      color: $neutral-dark;
      padding: 4.6rem 3.6rem;
      margin-bottom: 5rem;

      &-section-title {
        color: $neutral-dark;

        @include font-20;
        // @include font-bold;

        &.--top-margin {
          margin-top: 5.2rem;
        }
      }

      &-signatures {
        display: grid;
        grid-template-columns: 2fr 3fr 1fr;
        grid-column-gap: 3rem;

        &-title {
          color: $neutral-dark;
          @include font-14;
        }

        &-placeholder {
          height: 3.6rem;
          width: 100%;
          border-bottom: 0.1rem solid $neutral-dark;
        }
      }
    }
  }

  &__canvas {
    position: fixed;
    top: 100%;
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
}
