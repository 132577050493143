@import "../../../../../assets/scss/_settings.colors.scss";
@import "../../../../../assets/scss/_elements.table.scss";
@import "../../../../../shared";

.iwk-families-row {
  @include table-row;

  &__cell {
    flex-basis: 25%;
    flex-grow: 0;

    &.-small {
      flex-basis: 10%;
    }

    &.-large {
      flex-basis: 40%;
      margin-right: 2rem;
    }

    &-invite {
      width: 13rem;

      &:disabled {
        opacity: 1;
      }
    }

    &-button {
      @include table-button;
      margin-left: -1.4rem;
    }

    &-icon {
      height: 2rem;
      cursor: pointer;

      &.-edit {
        margin-left: 2.4rem;
      }
    }

    @media(min-width: $supermax-width) {
      &.-extend {
        flex-basis: 10%;
      }
    } 
  }
}
