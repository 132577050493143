// branding colors
$brand-blue: #5768A8;
$brand-blue-light: #6C82D1;
$brand-blue-dark: #475794;

$accent-light: #F7F4FD;
$accent-turquoise: #10A0A2;

$neutral-white: #FFFFFF;
$neutral-lighter: #F9F9F9;
$neutral-light: #DDDDDD;
$neutral-dark: #444444;
$neutral-darkest: #000000;

// form control colors
$form-control-border: #DEE2E6;
$form-control-text: #212529;
$form-control-error: #dc3545;