@import "../../assets/scss/_tools.fonts.scss";
@import "../../assets/scss/_tools.forms.scss";
@import "../../assets/scss/_tools.login-layout.scss";
@import "../../assets/scss/_tools.links.scss";

.iwk-change-password {
  @include login-layout;

  &__info {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 3.6rem;

    &-header {
      @include font-32;
    }

    &-message {
      @include font-16;
      line-height: 2.4rem;
      margin: 1.5rem 0;
      text-align: center;
    }
  }

  &__form {
    width: 32rem;

    &-group {
      margin-top: 2.3rem;
      width: 100%;

      &.--checkbox {
        display: flex;
        flex-direction: row-reverse;
        margin-bottom: 0.7rem;

        & label {
          margin: 0;
          margin-left: 0.8rem;
        }
      }

      & input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }
    }

    &-error {
      @include form-error;
    }

    &-actions {
      display: flex;
      justify-content: center;
      margin-top: 3.5rem;
    }

    &-inline-button {
      @include link;
      background: transparent;
      border: none;
      display: inline;
      margin: 0;
      padding: 0;
    }
  }

  &__changed {
    &-header {
      @include font-32;
    }

    &-message {
    }

    &-button {
    }
  }
  &__form-note {
    border: 0.1rem solid #f5f4f4;
    border-radius: 0.3rem;
    margin-bottom: 1rem;
    padding: 1rem;
    font-size: 1.4rem;
    text-align: left;
    p {
      margin-bottom: 0;
    }
    ul {
      margin: 0.2rem 0 0 0;
      padding-left: 3rem;
    }
  }
}
