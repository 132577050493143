@import "../../../../../assets/scss/_settings.colors.scss";
@import "../../../../../assets/scss/_elements.table.scss";

.iwk-staff-row {
  @include table-row;

  &__cell {
    @include table-cell;
    width: 0;

    &-invite {
      width: 13rem;

      &:disabled {
        opacity: 1;
      }
    }

    &-button {
      @include table-button;
    }

    &-empty {
      padding: 1rem;
      width: 4rem;
    }

    &-icon {
      height: 2rem;
      cursor: pointer;

      &.-edit {
        margin-left: 2.4rem;
      }
    }
  }
}
