@import "../../assets/scss/_tools.forms.scss";

.iwk-form-modal {

  & .--overflow-scroll {
    overflow: scroll;
  }

  & .--overflow-visible {
    overflow: visible;
  }

  .form-control {
    width: 100%;
  }

  .form-group {
    justify-self: flex-start;
    margin-bottom: 2.3rem;
    text-align: left;
  }

  .invalid-feedback {
    @include form-error;
    height: 100%;
    width: 32rem;
  }

  .form-note {
    border: 0.1rem solid #f5f4f4;
    border-radius: 0.3rem;
    margin-bottom: 1rem;
    padding: 1rem;
    font-size: 1.4rem;
    text-align: left;
    p {
      margin-bottom: 0;
    }
    ul {
      margin: 0.2rem 0 0 0;
      padding-left: 3rem;
    }
  }

  .form-error {
    font-size: 1.4rem;
    color: red;
  }

  .form-password {
    display: flex;
  }
}