@import "../../assets/scss/_tools.login-layout.scss";
@import "../../assets/scss/_tools.fonts.scss";

.iwk-unauthorized {
  @include login-layout;

  &__info {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 3.6rem;

    &-header {
      @include font-32;
      margin-bottom: 3rem;
    }

    &-message {
      @include font-16;
      line-height: 2.4rem;
      margin-bottom: 3.3rem;
      text-align: center;
      width: 26.6rem;
    }
  }
}