@import "../../assets/scss/_settings.colors.scss";

.iwk-checkbox {
  &__box {
    background-color: $neutral-white;
    border: 0.1rem solid $neutral-light;
    border-radius: 0.2rem;
    height: 2rem;
    padding: 0;
    text-align: center;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    &.-checked,
    &.-full {
      background-color: $accent-turquoise;
      border: 0.1rem solid $accent-turquoise;
    }

    &.-error {
      border: 0.1rem solid $form-control-error;
    }

    &-checkmark {
      visibility: hidden;

      .iwk-checkbox__box.-checked & {
        height: 1.25rem;
        position: relative;
        visibility: visible;
        width: 1.25rem;
      }
    }
  }
}
