@import "../../assets/scss/_settings.colors.scss";
@import "../../assets/scss/_tools.forms.scss";
@import "../../assets/scss/_tools.login-layout.scss";

.iwk-login {
  @include login-layout;

  &__form {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 3.1rem;
    width: 32rem;

    &-group {
      margin-top: 2.3rem;
      width: 100%;
    }

    &-error {
      @include form-error;
    }

    &-button {
      display: flex;
      margin: 4.9rem 0;

      &-spinner {
        margin-left: 1rem;
      }
    }
  }
}