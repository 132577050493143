@import "./_tools.fonts";
@import "./_settings.colors";

@mixin tab {
  @include font-14;
  @include font-regular;
  align-items: center;
  border-radius: 0.2rem 0.2rem 0 0;
  color: $brand-blue;
  display: flex;
  justify-content: center;
  margin: 0 0.25rem;
  padding: 1rem 2.0rem;
  text-decoration: none;

  &.-active {
    @include font-16;
    @include font-bold;
    background-color: $neutral-lighter;
  }

  &:hover {
    background-color: $brand-blue;
    color: $neutral-white;
  }
}
