@import "../../assets/scss/_tools.fonts.scss";

.--terms-of-service {
  & .modal-content {
    overflow: auto;
  }

  & .modal-body {
    text-align: left;

    & h3 {
      @include font-bold;
      @include font-18;
      font-weight: 800;
      margin-bottom: 0.8rem;
    }

    & p {
      margin-bottom: 3.2rem;
    }
  }

  & .modal-footer {
    padding-top: 2.2rem;
  }
}
